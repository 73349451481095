import React, { useState, useEffect } from 'react';

import { Dialog, Card } from '@mui/material';
import { images, icons } from "../../helpers/constants";
import { useTranslation } from "react-i18next";
import Button from '../Buttons/Button';
import DeviceSelectionRow from '../Devices/DeviceSelectionRow';
import * as request from "../../services/FetchService";
import { useContextData } from '../../components/ContextData';
import { useIsMobile } from '../../helpers/customHooks';
import toast from 'react-hot-toast';
import { staticSort } from '../../helpers/helperFunctions';


const ShareDevice = (props) => {
    const MOBILE = useIsMobile();
    const { t } = useTranslation();
    const { deviceListCompact, setDeviceListCompact } = useContextData();
    const [show, setShow] = useState(props.show)
    const [email, setEmail] = useState("")
    const [errorText, setErrorText] = useState("")
    const [errorTextDevice, setErrorTextDevice] = useState("")
    const [allSelected, setAllSelected] = useState(false)
    const [devices, setDevices] = useState([])
    
    useEffect(() => {
        setShow(props.show)
    },[props.show])

    useEffect(() => {
        let sorted = staticSort(props.devices, "name")
        setDevices(sorted)
    }, [props.devices])
    

    const handleClose = () => {
        props.onClose()
      //  setShow(false)
    }

    const handleChange = (field, e) => {
        setErrorText("")
        setEmail(e.target.value)
    }


    const selectDevice = (index) => {
        setErrorTextDevice("")
        let updatedDevices = [...devices];
        if (index !== -1) {
            var device = updatedDevices[index];
            if (device.selected !== true){
                device.selected = true;
            }else{
                device.selected = false;
            } 
            updatedDevices[index] = device;
            setDevices(updatedDevices);
          }
    }

    useEffect(() => {
        setErrorTextDevice("")
		if(allSelected && devices.length > 0){
			let updatedDevices = [...devices];
            updatedDevices.map((device, index) => {
				device.selected = true
            })
			setDevices(updatedDevices)
		}else{
            if(devices.length > 0){
                let updatedDevices = [...devices];
                updatedDevices.map((device, index) => {
                    device.selected = false
                })
                setDevices(updatedDevices)
            }
		}
    }, [allSelected])



    const closeModal = () => {
        setErrorTextDevice("")
        setErrorText("")
        setEmail("")
        setAllSelected(false)
        props.onClose()
    }

    const shareDevice = () => {
        if(!devices?.some(device => device.selected)){
            setErrorTextDevice(t("settings.pick_one_device"))
            return
        }
        if(email === ""){
            setErrorText("settings.enter_email")
            return
        }
        let selectedDeviceIds = devices?.filter(device => device.selected).map(device => device.id);
        request.post("", "device/share/create", {deviceid: selectedDeviceIds, email: email}).then((response) => {
            if(response.data.successful){
                closeModal()
                toast.success(t("toast.success"))
                props.updateDevices()
            }else{
                toast.error(response.data.message)
            }
        })

    }

    return(
        <>
        <Dialog fullWidth={MOBILE ? true : false} open={show} onClose={handleClose}>
            <Card className="add_device_dialog d-flex flex-column">
                <div className="d-flex flex-row p-3 justify-content-between border-settings align-items-center">
                    <div className='d-flex align-items-center'>
                        <img className='primary-icon' src={icons.sharing} alt=""/>
                        <h3 className="primary ms-2">{t("settings.share_device")}</h3>
                    </div>
                    <img className="cursor-pointer" src={icons.close_gray} onClick={() => props.onClose()} alt="..." /> 
                </div>
                <div className="d-flex flex-row mt-3">
                    <div className={`${MOBILE ? "d-none" : ""} primary w-50 bg-white d-flex flex-column align-items-center justify-content-center p-3`}>
                        <div className="round-image d-flex justify-content-center align-items-center mb-2">
                            <img src={icons.user} alt={""} />
                        </div>
                        <h3>{t("settings.share_device")}</h3>
                        <p className='text-center mt-2'>{t("settings.share_device_body")}</p>
                    </div>
                    <div className={`${MOBILE ? "w-100" : "w-50"} bg-white d-flex flex-column pb-3 px-3`}>
                        <p className="primary bold">{t("settings.account_to_share")}</p>
                        <form>
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control bg-light-grey"
                                    id="email"	
                                    value={email}
                                    onChange={(e) => {
                                        handleChange("email", e);
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                        e.preventDefault();// Prevent the default Enter key behavior
                                        shareDevice("LOL")
                                        }
                                    }}
                                />
                                <div className="invalid-feedback ms-1 bottom">
                                    {errorText === "" ? "" : errorText}
                                </div>
                            </div>
                        </form>
                        <div>
                        <p className="primary bold mt-3">{t("settings.devices_to_share")}</p>
                        {devices?.length === 0 
                        ? <p>{t("devices.no_devices")}</p>
                        : 
                        <div className={`share_device_list scrollable-y shadow-small`}>
                            <DeviceSelectionRow 
                                text={t("settings.choose_all")}
                                selected={devices?.some(device => !device.selected) ? false : true}
                                onClick={() => setAllSelected(!allSelected)}
                            />
                            {devices?.map((device, index) => (
                                <DeviceSelectionRow
                                    key={index}
                                    index={index}
                                    text={device.name}
                                    selected={device.selected ? true : false}
                                    onClick={() => selectDevice(index)}
                                />
                            ))}
                        </div>
                        }
                        <div className="invalid-feedback ms-1">
                            {errorTextDevice === "" ? "" : errorTextDevice}
                        </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex px-3 justify-content-between">
                    <Button onClick={() => closeModal()} btnClass="my-3 btn-secondary w-100 me-3">{t("settings.cancel")}</Button> 
                    <Button btnClass="btn-primary my-3 w-100 ms-3" onClick={() => shareDevice("LOL")}>{t("settings.share")}</Button>
                </div>
            </Card>
        </Dialog>
        </>
    )
}

export default ShareDevice;