import React, { createRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../components/Buttons/Button";
import EventRow from "../components/Events/EventRow";
import EventRowMobile from "../components/Events/EventRowMobile";
import Searchbar from "../components/Searchbar/Searchbar";
import { icons } from "../helpers/constants";
import * as request from "../services/FetchService";

import { Card, Collapse } from "@mui/material";
import toast from "react-hot-toast";
import DragComponent from "../components/DragComponent";
import EventRowPlaceholder from "../components/Events/EventRowPlaceholder";
import { useIsMobile } from '../helpers/customHooks';
import { translateEvents, translateReverse } from "../helpers/helperFunctions";
import { useContextData } from '../components/ContextData';
import DeleteEvents from "../components/Modals/DeleteEvents";

const Events = (props) => {
	const MOBILE = useIsMobile();
	const { deviceListCompact, setDeviceListCompact } = useContextData();
	const [deviceList, setDeviceList] = useState([])
	const [eventListFull, setEventListFull] = useState([])
	const [eventList, setEventList] = useState([])
	const { i18n, t } = useTranslation();
	const listInnerRef = createRef();
	const [eventScrollList, setEventScrollList] = useState([])
	const [showActions, setShowActions] = useState(false)
	const [deleteToggle, setDeleteToggle] = useState(false)
	const [allSelected, setAllSelected] = useState(false)
	const [deleteArray, setDeleteArray] = useState([])
	const [loading, setLoading] = useState(true)
	const [toggleDrag, setToggleDrag] = useState(false)
	const [totalPages, setTotalPages] = useState(-1)
	const [currentEvent, setCurrentEvent] = useState(-1)
	const [initialLoad, setInitialLoad] = useState(true)

	const [currentFilter, setCurrentFilter] = useState({type: "date", order: true})
	const [currentTypes, setCurrentTypes] = useState(null)
	const [currentDevices, setCurrentDevices] = useState(null)
	const [clearSearch, setClearSearch] = useState(false)
	const [currentPage, setCurrentPage] = useState(1)
	const [searchString, setSearchString] = useState("")
	const [visibleEvents, setVisibleEvents] = useState({})
	const [loadingBottom, setLoadingBottom] = useState(false)
	const [modals, setModals] = useState({
		delete_events: false
	});

	useEffect(() => {
		// This function will be called whenever the language changes
		const onLanguageChanged = () => {
		  setLoading(true)
		  setEventList([])
		  setTimeout(() => {
			getEventsPost(currentPage, currentFilter, currentTypes, currentDevices)
		  }, 500);

		  // You can perform other actions here when the language changes
		};
	
		// Register the event listener for language changes
		i18n.on('languageChanged', onLanguageChanged);
	
		// Cleanup function to remove the event listener
		return () => {
		  i18n.off('languageChanged', onLanguageChanged);
		};
	  }, [i18n]); // Dependency array to ensure effect is set up correctly

	const onScroll = () => {
		if(listInnerRef.current){
			const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
			if (scrollTop + clientHeight === scrollHeight && !loading) {
				if(totalPages > currentPage){
					setLoading(true)
					getEventsPost(currentPage + 1, currentFilter, currentTypes, currentDevices)
					setCurrentPage(currentPage + 1)
					setLoadingBottom(true)
					// setEventList(eventList => [...eventList, ...eventScrollList[page]])
				}
			}
		}
	}
	useEffect(() => {
		window.addEventListener('scroll', onScroll)
		getDevices()
		return() => {
			window.removeEventListener('scroll', onScroll)
		}
	}, []);

	const toggleModal = (modalName) => {
		setModals((prevState) => ({
			...prevState,
			[modalName]: !prevState[modalName],
		}));
	};

	const deleteAllEvents = () => {
		let data = {
			delete_all_events: true
		}
		request.post("", "events/delete", data).then((response) => {
			console.log("DELETE ALL: ", response);
			if(response.data){
				setLoading(true)
				setEventList([])
				toast.success(t("toast.done"))
				setTimeout(() => {
					getEventsPost(1)
					setCurrentPage(1)
					toggleModal("delete_events")
				}, 250);
			}
		})
	}

	const getDevices = () => {
		if(deviceListCompact.length === 0){
			request.get("devices/compactinfo").then((response) => {
				setDeviceListCompact(response.data.devices)
				setDeviceList(response.data.devices)
				// setLoading(false)
			});
		}else{
			setDeviceList(deviceListCompact)
		}
	}

	const getEventsPost = (page = currentPage, filter = null, type = null, deviceid = null) => {
		//setEventList([])
		let data = {
			page: page,
			limit: 100
		}
		if(filter){
			data.sort = filter.order ? "latest" : "oldest"
		}
		if(type){
			data.type = type
		}
		if(deviceid){
			data.deviceid = deviceid
		}
		let url
		if(process.env.REACT_APP_FLAVOR === "bike"){
			url = "events/list"
		}else{	
			url = "v2/events/list"
		}
		request.post("", url, data).then((response) => {
			if(!response.data.events){
				// toast.error("FEL!")
				setLoading(false)
				setLoadingBottom(false)
				setEventList([])
				return
			}
			const filteredEvents = response.data.events.filter(event => event.additionalinfo?.info1?.owner !== false);
			let secondFilter;
			const validTypes = [
				"ignitionOn",
				"ignitionOff",
				"deviceOverspeed",
				"vibration",
				"geofenceEnter",
				"geofenceExit",
				"lowBattery"
			];
			
			if (process.env.REACT_APP_FLAVOR === "bike") {
				secondFilter = filteredEvents.filter(event => validTypes.includes(event.type));
			} else {
				secondFilter = filteredEvents;
			}
			setVisibleEvents(response.data.visiblefilterswitch)
			setEventList(eventList => [...eventList, ...secondFilter])
			setEventListFull(eventListFull => [...eventListFull, ...secondFilter])
			setTotalPages(response.data.totalPages)
			setLoading(false)
			setLoadingBottom(false)
			setInitialLoad(false)
		})
	}

	useEffect(() => {
		if(allSelected){
			let updatedDevices = [...eventListFull];
            updatedDevices.map((device, index) => {
				device.selected = true
            })
			setEventListFull(updatedDevices)
		}else{
			let updatedDevices = [...eventListFull];
            updatedDevices.map((device, index) => {
				device.selected = false
            })
			setEventListFull(updatedDevices)
		}
    }, [allSelected])

	const selectEvent = (index) => {
        let updatedEvents = [...eventList];
		let correct = updatedEvents.findIndex(id => id.eventid === index)
        if (index !== -1) {
            var event = updatedEvents[correct];
            if (event.selected !== true){
                event.selected = true;
            }else{
                event.selected = false;
            }
            updatedEvents[correct] = event;
			setEventList(updatedEvents)
            setEventListFull(updatedEvents);
		}
    }

	const checkChecked = () => {
		let events = eventList.filter(event => event.selected);
		let data = {
			eventid: events.map(event => event.eventid)
		}
		request.post("", "events/delete", data).then((response) => {
			if(response.data){
				toast.success(t("toast.done"))
				setLoading(true)
				setEventList([])
				setTimeout(() => {
					setTimeout(() => {
						getEventsPost(1)
						setCurrentPage(1)
						//toggleModal("delete_events")
					}, 250);
				}, 250);
			}
		})
	}

	const renderCard = () => {
		return (
		<Card className={`py-0 px-1 mt-1 mb-2`}>
            <div className="d-flex flex-row justify-content-between align-items-center px-1 pt-3 pb-2 border-bottom">
                <h4 className="mb-2">{t("events.actions")}</h4>
                <img
                    className="mb-2 size_25 cursor-pointer close_img"
                    src={icons.close_gray}
                    onClick={() =>setShowActions(!showActions)}
                    alt="..."
                />
            </div>
			<ul className="list-group list-group-flush">
				<li
					className="cursor-pointer list-group-item d-flex justify-content-between align-items-center"
					onClick={() => {setDeleteToggle(!deleteToggle)}}
				>
				<p className={`my-2 ${deleteToggle ? "primary" : "dark_grey"}`}>
					{t("events.delete_alarms")}
				</p>
				</li>
			</ul>
        </Card>
		)
	}


	const closeActions = () => {
		setDeleteToggle(false)
		setShowActions(false)
		setDeleteArray([])
	}

	const filterOrder = (filter) => {
		setLoading(true)
		setEventList([])
		getEventsPost(1, filter, currentTypes, currentDevices)
		setCurrentPage(1)
		setCurrentFilter(filter)
	}
	const filterSearch = (searchWord) => {
		setLoading(true);
		
		// Normalize and split the searchWord into an array of terms
		const terms = searchWord.trim().toLowerCase().split(/\s+/);
	
		if (terms.length === 0 || (terms.length === 1 && terms[0] === "")) {
			// If there are no valid terms, reset everything
			if (!initialLoad) {
				setCurrentTypes([]);
				setSearchString("");
				setEventList([]);
				setCurrentPage(1);
				setCurrentDevices([]);
				getEventsPost(1, currentFilter, [], []);
			}
		} else {
			// Update the search string in state
			setSearchString(searchWord.trim().toLowerCase());
	
			// Initialize sets to collect matching IDs and types
			let matchingDevicesSet = new Set();
			let matchingEventsSet = new Set();
	
			// Process each term separately
			terms.forEach(term => {
				let matchingDevices = deviceList.filter(device => 
					device.name.toLowerCase().includes(term)
				).map(device => device.id);
				matchingDevices.forEach(id => matchingDevicesSet.add(id));
	
				let termMatches = translateReverse(t, term) || [];
				termMatches.forEach(event => matchingEventsSet.add(event));
			});
	
			// Convert sets back to arrays
			let matchingDevices = [...matchingDevicesSet];
			let combined = [...matchingEventsSet];
	
			// Manage the case where there are no matches
			if (combined.length === 0 && matchingDevices.length === 0) {
				combined = terms; // fallback to just the search terms
				matchingDevices = null;
			} else if (combined.length > 0 && matchingDevices.length === 0) {
				matchingDevices = null;
			} else if (combined.length === 0 && matchingDevices.length > 0) {
				combined = null;
			}	
			// Update states with the computed values
			setCurrentDevices(matchingDevices);
			setCurrentTypes(combined);
			setEventList([]);
			setCurrentPage(1);
			getEventsPost(1, currentFilter, combined, matchingDevices);
		}
	};


	useEffect(() => {
		if(!deleteToggle){
			let updatedDevices = [...eventListFull];
            updatedDevices.map((device) => {
				device.selected = false
            })
			setEventListFull(updatedDevices)
		}
	}, [deleteToggle])

	const filterTypes = (data) => {
		setLoading(true)
		// First, extract the types from the data array where selected is true
		let filtered = data.filter(item => item.selected).map(item => item.type);
		setCurrentTypes(filtered)
		if(filtered.length > 0){
			setEventList([])
			setClearSearch(!clearSearch)
			if(searchString === ""){
				getEventsPost(1, currentFilter, filtered, currentDevices)

			}
			setCurrentPage(1)
		}else{
			setEventList([])
			setCurrentPage(1)
			setClearSearch(!clearSearch)
			if(searchString === ""){
				getEventsPost(1, currentFilter, filtered, currentDevices)

			}
		}
	};


	return (
		<>
			<div className="section-left_bottom_data" id="resizable-element">
				{/* TITLE */}
				{/* TITLE */}
				<DragComponent dragId="resizable-element" initialH="60px" maxH={80} minH="60px" close={toggleDrag} setClose={() => setToggleDrag(false)}/>
				<div className="d-flex flex-row mt-4 align-items-center page_header">
					<img src={icons.sidebar.bell_active} className="title_icon primary-icon" alt="..." />
					<h1 className="h1 primary ms-3">{t("events.alarms")}</h1>
				</div>
				<div className="my-3">
					<Searchbar sort={true} clearSearch={clearSearch} visibleEvents={visibleEvents} submitSearch={(data) => filterSearch(data)} updateTypes={(data) => filterTypes(data)} updateOrder={(data) => filterOrder(data)}/>
				</div>
				{/* Actions */}
				<div className="position-relative mb-3 d-flex">
					<Button className={`${!deleteToggle ? "d-none" : ""}`} btnClass="btn-danger me-3" onClick={() => toggleModal("delete_events")}>{t("events.delete_all")}</Button>
					{/* <div className={`me-3 align-self-center ${!deleteToggle ? "d-none" : !eventListFull?.some(device => !device.selected) ? "bg-checked" : "bg-grey"} size_20 border-sm d-flex justify-content-center p-1`} onClick={() => setAllSelected(!allSelected)}>
						{!eventListFull?.some(device => !device.selected) && <img className="white-icon" src={icons.check} alt={""} />}
					</div> */}
					<Button btnClass="btn-left-section" onClick={() => setShowActions(!showActions)} icon={icons.plus}>
						{t("events.actions")}
					</Button>
					<Collapse in={showActions} className="position-absolute top-100 w-50">
						{renderCard()}
					</Collapse>
				</div>
				<div ref={listInnerRef} onScroll={() => onScroll()} className={`alarm_list ${deleteToggle ? "pushed" : ""} scrollable-y shadow-small`}>
					{loading &&
						<EventRowPlaceholder />
					}
					{eventList && eventList.map((event, index) => {
						return(
							MOBILE
							? <EventRowMobile 
								event={event} 
								index={index} 
								key={index} 
								checkboxVisibility={deleteToggle}
								currentEvent={currentEvent}
								setCurrentEvent={(data) => setCurrentEvent(data)} 
								focusOnMap={() => setToggleDrag(true)} 
								setEventToggle={(data) => selectEvent(data)}/>
							: <EventRow 
								event={event} 
								index={index} 
								key={index} 
								checkboxVisibility={deleteToggle}
								currentEvent={currentEvent}
								setCurrentEvent={(data) => setCurrentEvent(data)} 
								setEventToggle={(data) => selectEvent(data)}/>
						)			
					})}
					{loadingBottom &&
						<EventRowPlaceholder />
					}
				</div>
				{(!eventList || eventList.length === 0) && !loading &&
					<h3 className="bold dark-grey text-center mt-5">{t("events.no_events")}</h3>
				}
				<div className={`d-flex justify-content-end mt-3 ${!deleteToggle && "d-none"}`}>
					<Button btnClass="btn-secondary me-3" onClick={() => closeActions()}>{t("events.cancel")}</Button>
					<Button btnClass="btn-primary" onClick={() => checkChecked()}>{t("events.delete_alarms")}</Button>
				</div>
			</div>
			<DeleteEvents show={modals.delete_events} onClose={() => toggleModal("delete_events")} deleteAll={deleteAllEvents} />

		</>
	);
};

export default Events;
